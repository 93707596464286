// COMMON CSS START
.flex {
    display: flex;
}

.flexSpaceBtw {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flexCenter {
    display: flex;
    justify-content: center;
}

.flexRowCenter {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.paddingTB8 {
    padding-top: 8px;
    padding-bottom: 8px;
}

.paddingLR8 {
    padding-left: 8px;
    padding-right: 8px;
}

.commonList {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.link {
    li {
        a {
            color: $color-system-white;
            text-decoration: underline;
        }
    }
}

.text14 {
    font-size: 0.875rem;
}

.linkText {
    color: $color-system-green;
}

.bold {
    font-weight: bold !important;
}

.textCenter {
    text-align: center;
}

.hide {
    display: none;
}

.hidden {
    visibility: hidden;
}

.mt0 {
    margin-top: 0 !important;
}

.mt2 {
    margin-top: 2px;
}

.mt8 {
    margin-top: 8px !important;
}

.mt16 {
    margin-top: 16px !important;
}

.mt24 {
    margin-top: 24px !important;
}

.mt32 {
    margin-top: 32px !important;
}

.ml0 {
    margin-left: 0 !important;
}

.ml8 {
    margin-left: 8px !important;
}

.ml16 {
    margin-left: 16px !important;
}

.mb16 {
    margin-bottom: 16px !important;
}

.mb40 {
    margin-bottom: 40px;
}

.mr8 {
    margin-right: 8px !important;
}

.mr16 {
    margin-right: 16px;
}

.mr40 {
    margin-right: 40px;
}

.mb20 {
    margin-bottom: 20px;
}

.errorMessage {
    font-size: 0.875rem;
    color: $color-system-red-primary;
}

.commonProgress {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 24px 0;

    img {
        height: 56px;
    }
}

.commonLoader {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    background-color: rgba(0, 0, 0, .45);
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;

    .commonProgress {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            height: 100px;
        }
    }
}

.commonNewLoader {
    background: #F4F7F8 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    margin-top: 60px;
    height: calc(100vh - 220px);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .commonProgress {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
            height: 100px;
        }

        p {
            margin-top: 16px;
            font-size: 18px;
            line-height: 27px;
            font-weight: normal;
            letter-spacing: 0px;
            color: #282D32;
            opacity: 1;
        }
    }
}

.inlineLoaderIcon {
    height: 20px;
    margin-left: 10px;
}

.bulletList,
.numberList {
    li {
        font-size: 1rem;
        line-height: 25px;
        text-transform: none;
        margin-bottom: 16px;

        &:last-child {
            margin-bottom: 0;
        }

        @media screen and (max-width: 1100px) {
            font-size: 0.875rem;
        }
    }
}

.pointer {
    cursor: pointer;
}

.ellip {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.threeLinerText {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.rotateIcon {
    transform: rotateZ(90deg);
}

.rounded-circle {
    border-radius: 50% !important;
}

// .exploreBoxCont{
//     background: #EDF0F1 0% 0% no-repeat padding-box;
//     border-radius: 10px;
//     opacity: 1;
//     padding: 16px 16px;    
// }

.p-0 {
    padding: 0 !important;
}

.ReactModal__Overlay {
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.15) !important;

    .close-con {
        text-align: end;

        .fa-xmark {
            font-size: 32px;
            cursor: pointer;
        }
    }

    .map-modal {
        .map-svg {
            height: calc(100vh - 200px);

            .in-active {
                opacity: 0.65;
            }

            .active {
                opacity: 1;
            }
        }

        .map-svg g[data-code] {
            cursor: pointer;
        }

        .zoom-btn-group {
            right: 14px;
        }
    }

    .custom-modal-content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
    }

    @media screen and (max-width: 767px) {
        .map-modal {
            .map-svg {
                width: 100%;
            }
        }
    }
}

.Toastify__toast--error {
    background: #F02245 0% 0% no-repeat padding-box;
    border-radius: 6px;
    color: #FFFFFF;
}

.Toastify__toast--error::after {
    position: absolute;
    color: #333333;
    font-size: 15px;
    font-weight: 700;
    left: 265px;
    padding-top: 14px !important;
}

.Toastify__toast--error::before {
    position: relative;
    z-index: 100000;
    left: 12px;
    top: 6px;
}

.Toastify__toast--success {
    background: #00A7B7 0% 0% no-repeat padding-box;
    border-radius: 6px;
    color: #FFFFFF;
}

.Toastify__toast--success::before {
    position: relative;
    z-index: 100000;
    left: 12px;
    top: 6px;
}

.Toastify__toast--success::after {
    position: absolute;
    color: #333333;
    font-size: 15px;
    font-weight: 700;
    left: 265px;
    padding-top: 14px !important;
}

.Toastify__toast-body {
    .Toastify__toast-icon {
        width: auto;

        .fa-check {
            font-size: 24px;
        }
    }
}

.Toastify__toast-body>div:last-child {
    font-size: 16px;
    line-height: 18px;
    font-weight: 500;
    color: #FFFFFF;
    opacity: 1;
}

.Toastify__toast>button>svg {
    display: none;
}

.Toastify__toast {
    .fa-xmark {
        position: absolute;
        top: 0;
        right: 16px;
        margin-top: auto;
        margin-bottom: auto;
        bottom: 0;
        font-size: 24px;
    }
}

.custom-accordion {
    .accordion__item {
        margin-bottom: 16px;

        .accordion__heading {
            background: transparent;

            .accordion__button {
                position: relative;
                font-size: 16px;
                line-height: 25px;
                font-weight: 600;
                letter-spacing: 0px;
                color: #282D32;
                background: #FFFFFF;
                border: 1px solid #C3C3C3;
                border-radius: 6px;

                &::before {
                    display: none;
                }

                .accordion__state {
                    position: absolute;
                    right: 12px;
                    top: 16px;
                    bottom: 0;

                    .fa-plus {
                        font-size: 28px;
                        color: #00A7B7;
                    }

                    .fa-minus {
                        font-size: 28px;
                        color: #FD7278;
                    }
                }
            }
        }

        .accordion__panel {
            background: #FFFFFF;
            border: 1px solid #C3C3C3;
            border-top: none;
            border-radius: 6px;
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
            font-size: 16px;
            line-height: 25px;
            font-weight: normal;
            letter-spacing: 0px;
            color: #000000;
        }
    }

    .accordion__item+.accordion__item {
        border-top: none;
    }
}

.logo-image {
    margin-bottom: 0.875rem;

    &.natwest {
        img {
            height: auto;
            width: 200px;
            object-fit: contain;
        }
    }

    &.hsbc {
        img {
            height: auto;
            width: 232px;
            object-fit: contain;
        }
    }
}

.no-data-container {
    background: #F4F7F8;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: calc(100vh - 250px);
    height: 100%;

    p {
        margin-top: 16px;
        text-align: center;
        font-size: 18px;
        line-height: 27px;
        font-weight: normal;
        letter-spacing: 0px;
        color: #282D32;
        opacity: 1;
        margin-bottom: 16px;
    }
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .container {
        width: 540px;
    }
}

@media (min-width: 768px) {
    .container {
        width: 720px;
    }
}

@media (min-width: 992px) {
    .container {
        width: 960px;
    }
}

@media (min-width: 1200px) {
    .container {
        width: 1140px;
    }
}

@media (min-width: 1500px) {
    .container {
        width: 1500px;
    }
}

.section {
    display: flex;
    justify-content: space-between;
    padding-top: 80px;
    padding-bottom: 80px;

    @media (max-width: 1100px) {
        display: inline-block;
    }
}

.apexcharts-text {
    font: normal normal normal 16px/25px Poppins !important;
    @media (max-width: 1000px) {
       font-size: 14px !important;
    }
    color: $color-system-heading !important;
}
.apexcharts-text tspan{
    font-size: 14px !important;
}

.apexcharts-legend-text {
    font: normal normal normal 12px/16px Poppins !important;
    color: $color-system-heading !important;
}

.column-reverse {
    @media screen and (max-width: 1000px) {
        gap: 40px;
        flex-direction: column-reverse;
    }
}

.explore-link {
    font-size: 16px;
    line-height: 25px;
    font-weight: 500;
    letter-spacing: 0px;
    color: $color-system-heading;
    opacity: 1;

    @media screen and (max-width: 1000px) {
        font-size: 12px;
        line-height: 18px;
    }

    svg {
        margin-left: 8px;
        font-size: 16px;
        color: $color-system-heading;

        @media screen and (max-width: 1000px) {
            font-size: 10px;
        }
    }
}

.circle-wrapper {
    display: flex;
    justify-content: center;
    gap: 30px;

    @media screen and (max-width: 1000px) {
        gap: 10px;
    }
}

.circle-container {
    max-width: 165px;
}

.circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 165px;
    height: 165px;
    border-radius: 50%;
    border: 2px solid #000;
    text-align: center;
    font-size: 50px;
    line-height: 76px;
    font-weight: 600;
    color: #FFFFFF;
    @media screen and (max-width: 1000px) {
        width: 100px;
    height: 100px;
    font-size: 30px;
    line-height: 46px;
    }
}

.circle-text {
    display: flex;
    justify-content: center;
    color: $color-system-heading;
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    padding-top: 32px;
    @media screen and (max-width: 1000px) {
    font-size: 12px;
    line-height: 18px;
    }
}